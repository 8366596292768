<template>
  <main v-if="GET_ALL_TERMINAL_PRICINGS && GET_ALL_TERMINAL_PRICINGS.data">
    <trac-back-button>Back</trac-back-button>
    <div
      class="grid grid-cols-1 md:grid-cols-2 mt-12 mr-4 md:mr-0 my-4 md:my-0"
    >
      <div>
        <carousel
          :autoplayTimeout="5000"
          :autoplay="true"
          :per-page="1"
          :mouse-drag="true"
          :loop="true"
        >
          <slide>
            <div class="rounded-lg py-10 m-4">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction SOLO
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img
                    src="@/assets/images/terminals/TractionMpos.png"
                    class="h-40"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="rounded-lg m-4 py-10">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction SOLO
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img
                    src="@/assets/images/terminals/TractionMpos2.png"
                    class="h-40"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </slide>
          <slide>
            <div class="rounded-lg m-4 py-10">
              <p class="text-center text-sm to-gray-800 mt-2 font-medium">
                Traction SOLO
              </p>
              <div class="flex items-center mt-10 justify-center px-2">
                <div>
                  <img
                    src="@/assets/images/terminals/TractionMpos3.png"
                    class="h-40"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
      <div class="md:mb-0 md:px-20 mb-56">
        <trac-input
          placeholder="Number of Pos"
          class="leading-7"
          type="number"
          v-model="payload.quantity"
        ></trac-input>

        <div v-if="mposTerminal.accessories.length">
          <div @click="options = !options" class="flex relative items-center">
            <input
              readonly
              type="text"
              placeholder="Select Accessories (Optional)"
              class="
                border
                py-2
                mt-4
                text-accentDark
                leading-6
                mt-x
                px-4
                w-full
                text-xs
                rounded
                input-area
              "
            />
            <svg
              style="margin-right: 0rem;"
              class="fill-current absolute right-0 mt-3 text-gray-500"
              width="17px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
          <div class="mt-4 big-shadow rounded-md px-3 py-4" v-if="options">
            <div v-for="(items, i) in accessoriesArray" :key="i">
              <div class="grid grid-cols-3 items-center mt-2">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    :value="items"
                    @input="updatePayload($event, items, i)"
                  />
                  <div class="text-xs pl-2 capitalize">
                    {{ items.accessoriesType }}
                  </div>
                </div>
                <div
                  class="w-32 mx-auto border p-1 pl-2 text-xs text-primaryGray"
                >
                  {{ items.price | formatPrice }}
                </div>
                <div class="flex item-center justify-end">
                  <div
                    @click="decrease(i)"
                    class="
                      w-5
                      h-5
                      bg-primaryBlue
                      rounded
                      cursor-pointer
                      text-white
                      flex
                      item-center
                      justify-center
                    "
                  >
                    -
                  </div>
                  <div class="text-sm w-5 px-3">{{ items.quantity }}</div>
                  <div
                    @click="increase(i)"
                    class="
                      w-5
                      h-5
                      ml-2
                      bg-primaryBlue
                      cursor-pointer
                      rounded
                      text-white
                      flex
                      item-center
                      justify-center
                    "
                  >
                    +
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-end mt-4 my-3">
              <div class="text-sm text-black">
                Total: {{ totalPrice | formatPrice }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <select
            required
            name=""
            class="
              border
              py-3
              leading-7
              mt-4
              text-gray-500
              px-4
              w-full
              text-xs
              rounded
              input-area
            "
            id=""
            v-model="choose"
          >
            <option :value="null" disabled>Select Option Plan</option>
            <option value="outright">
              Outright Purchase ({{ outrightPurchase | formatPrice }})
            </option>
            <option value="lease">
              Lease to own ({{ leaseToOwn | formatPrice }})
            </option>
          </select>
        </div>
        <trac-input
          placeholder="Phone Number"
          class="leading-7 mt-4"
          type="number"
          v-model="payload.deliveryPhoneNumber"
        ></trac-input>
        <trac-input
          placeholder="Address"
          class="leading-7 mt-4"
          type="text"
          v-model="payload.deliveryAddress"
        ></trac-input>
        <trac-input
          placeholder="City"
          class="leading-7 mt-4"
          type="text"
          v-model="payload.deliveryCity"
        ></trac-input>
        <div>
          <div
            @click.stop="stateDropdown = !stateDropdown"
            class="
              border
              py-3
              leading-5
              mt-4
              text-gray-500
              pl-5
              w-full
              text-xs
              rounded
              input-area
            "
          >
            {{ payload.deliveryState || "Select State" }}
          </div>
          <div
            v-click-outside="close"
            v-if="stateDropdown"
            style="max-height: 19rem"
            class="
              h-auto
              max-w-sm
              w-full
              overflow-y-auto
              absolute
              bg-white
              mt-1
              z-10
              shadow
              border
              p-2
              rounded-md
            "
          >
            <input
              required
              type="text"
              v-model="stateSearch"
              class="
                shadow
                appearance-none
                text-xs
                border
                rounded
                w-full
                py-2
                leading-5
                px-3
                text-gray-700
                mt-1
                focus:outline-none
                focus:shadow-outline
              "
            />
            <div class="mt-2" v-for="state in formatStates" :key="state.name">
              <div class="capitalize text-sm" @click="updateState(state)">
                {{ state.name || "No Data available" }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            :class="{
              ' pointer-events-none': local_governments.length == 0,
            }"
            @click.stop="lgDropdown = !lgDropdown"
            class="
              border
              py-3
              leading-5
              mt-4
              text-gray-500
              pl-5
              w-full
              text-xs
              rounded
              input-area
            "
          >
            {{ payload.deliverylga || "Select Local Government" }}
          </div>
          <div
            v-click-outside="closeTwo"
            v-if="lgDropdown"
            style="max-height: 19rem"
            class="
              h-auto
              max-w-sm
              z-10
              w-full
              overflow-y-auto
              absolute
              bg-white
              mt-1
              shadow
              border
              p-2
              rounded-md
            "
          >
            <input
              required
              type="text"
              v-model="lgSearch"
              class="
                shadow
                appearance-none
                text-xs
                border
                rounded
                w-full
                py-2
                leading-5
                px-3
                text-gray-700
                mt-1
                focus:outline-none
                focus:shadow-outline
              "
            />
            <div class="mt-2" v-for="lg in formatLg" :key="lg">
              <div class="capitalize text-sm" @click="updateLg(lg)">
                {{ lg }}
              </div>
            </div>
          </div>
        </div>
        <trac-input
          placeholder="Delivery Date"
          class="leading-7 mt-4"
          type="date"
          :min="today"
          v-model="payload.deliveryDate"
        ></trac-input>
        <trac-input
          placeholder="Referral code"
          class="leading-7 mt-4"
          type="text"
          v-model="payload.saleRefCode"
        ></trac-input>

        <trac-button @click.native="sendRequest" class="w-full mt-4">
          <p>Send Request</p>
        </trac-button>
      </div>
    </div>
  </main>
  <trac-loading v-else />
</template>

<script>
import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { mapGetters } from "vuex";
import { eventBus } from "./../../main";
import ClickOutside from "vue-click-outside";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      today: null,
      stateSearch: "",
      lgSearch: "",
      stateDropdown: false,
      lgDropdown: false,
      states: [],
      local_governments: [],
      options: false,
      quantity: 0,
      accessories: [],
      initial: [],
      outrightPurchase: "",
      leaseToOwn: "",
      accessoriesPayload: [],
      choose: null,
      payload: {
        businessId: null,
        quantity: null,
        deliveryPhoneNumber: null,
        deliveryAddress: null,
        deliveryCity: null,
        deliveryState: null,
        deliveryDate: null,
        deliverylga: null,
        saleRefCode: null,
        terminalPrice: {
          terminalType: "MPOS",
          terminalImage: "",
          outrightPurchase: "",
          leaseToOwn: "",
          subscription: "",
          accessories: [
            {
              accessoriesType: "",
              price: "",
            },
          ],
        },
        accessoryRequest: [],
      },
    };
  },

  directives: {
    ClickOutside,
  },

  watch: {
    choose(x) {
      if (x === "outright") {
        this.payload.terminalPrice.outrightPurchase = this.outrightPurchase;
        this.payload.terminalPrice.leaseToOwn = "";
      } else {
        this.payload.terminalPrice.outrightPurchase = "";
        this.payload.terminalPrice.leaseToOwn = this.leaseToOwn;
      }
    },
  },
  methods: {
    updateState(state) {
      this.payload.deliveryState = state.name;
      this.local_governments = state.local_governments;
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    updateLg(lg) {
      this.payload.deliverylga = lg;
      this.lgDropdown = false;
      this.lgSearch = "";
    },
    close() {
      this.stateDropdown = false;
      this.stateSearch = "";
    },
    closeTwo() {
      this.lgDropdown = false;
      this.lgSearch = "";
    },
    async sendRequest() {
      if (this.payload.quantity === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Quantity",
        });
        return;
      }
      if (this.choose === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Options",
        });
        return;
      }
      if (this.payload.deliveryPhoneNumber === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Your Phone Number",
        });
        return;
      }
      var arr = ["080", "081", "090", "091", "070", "071"];
      var test1 = arr.some((el) =>
        this.payload.deliveryPhoneNumber.startsWith(el)
      );

      if (!test1) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      if (+this.payload.deliveryPhoneNumber.length !== 11) {
        eventBus.$emit("trac-alert", {
          message: "Phone Number not valid",
        });
        return;
      }
      if (this.payload.deliveryAddress === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Address",
        });
        return;
      }
      if (this.payload.deliveryCity === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter City",
        });
        return;
      }
      if (this.payload.deliveryState === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter State",
        });
        return;
      }
      if (this.payload.deliverylga === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Local Government",
        });
        return;
      }
      if (this.payload.deliveryDate === null) {
        eventBus.$emit("trac-alert", {
          message: "Enter Delivery Date",
        });
        return;
      }
      this.payload.quantity = +this.payload.quantity;

      let response = await this.$store.dispatch(
        "ADD_TERMINAL_REQUEST",
        this.payload
      );
      if (response.status) {
        eventBus.$emit("trac-alert", {
          message: response.message || "Terminal requested successfully",
        });
      }
      this.payload.quantity = null;
      this.payload.deliveryPhoneNumber = null;
      this.payload.deliveryAddress = null;
      this.payload.deliveryCity = null;
      this.payload.deliveryState = null;
      this.payload.deliverylga = null;
      this.payload.deliveryDate = null;
      this.payload.saleRefCode = null;
      this.choose = null;
      this.payload.accessoryRequest = [];
      this.options = false;
    },

    updatePayload(x, y, i) {
      if (x.target.checked) {
        let val = {
          accessoriesPrice: {
            accessoriesType: y.accessoriesType,
            price: y.oldPrice,
          },
          quantity: y.quantity,
        };
        this.$set(this.payload.accessoryRequest, i, val);
      } else {
        this.payload.accessoryRequest.splice(i, 1);
      }
    },
    increase(i) {
      let quantity = (this.accessories[i].quantity += 1);
      let price = this.initial[i].price;
      if (quantity + 1 > 0) {
        this.accessories[i].price += price;
        if (
          this.payload.accessoryRequest &&
          this.payload.accessoryRequest.length
        ) {
          this.payload.accessoryRequest[i].quantity = quantity;
        }
      }
      return quantity;
    },
    decrease(i) {
      let initial = this.accessories[i].quantity;
      let price = this.initial[i].price;

      if (initial > 1) {
        let quantity = (this.accessories[i].quantity -= 1);
        this.accessories[i].price -= price;
        if (
          this.payload.accessoryRequest &&
          this.payload.accessoryRequest.length
        ) {
          this.payload.accessoryRequest[i].quantity = quantity;
        }

        return quantity;
      }
    },
  },

  computed: {
    ...mapGetters(["GET_ALL_TERMINAL_PRICINGS"]),

    accessoriesArray() {
      return this.accessories;
    },

    mposTerminal() {
      return this.GET_ALL_TERMINAL_PRICINGS.data.items.find(
        (x) => x.terminalType === "MPOS"
      );
    },

    formatStates() {
      return this.states
        .filter((state) => {
          return state.name
            .toLowerCase()
            .includes(this.stateSearch.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    formatLg() {
      return this.local_governments
        .filter((state) => {
          return state.toLowerCase().includes(this.lgSearch.toLowerCase());
        })
        .sort((a, b) => a.localeCompare(b));
    },

    totalPrice() {
      if (
        this.payload.accessoryRequest &&
        this.payload.accessoryRequest.length
      ) {
        let areas = this.payload.accessoryRequest.map((x) => {
          return x.accessoriesPrice.price * x.quantity;
        });

        return areas.reduce((a, b) => a + b);
      } else {
        return 0;
      }
    },
  },
  async created() {
    const response = await this.$store.dispatch("GET_AVAILABLE_LOCATIONS");
    this.states = response.data;
    this.today = moment()
      .add(5, "d")
      .format("YYYY-MM-DD");
    await this.$store.dispatch("FETCH_TERMINAL_PRICINGS");
    this.payload.businessId = GET_USER_BUSINESS_ID();
    this.outrightPurchase = this.mposTerminal.outrightPurchase;
    this.leaseToOwn = this.mposTerminal.leaseToOwn;
    this.subscription = this.mposTerminal.subscription;
    this.payload.terminalPrice.accessories = this.mposTerminal.accessories;

    this.initial = [...this.mposTerminal.accessories].map((x) => {
      return {
        price: +x.price,
        accessoriesType: x.accessoriesType,
        quantity: 1,
      };
    });
    this.accessories = [...this.mposTerminal.accessories].map((x) => {
      return {
        price: +x.price,
        oldPrice: +x.price,
        accessoriesType: x.accessoriesType,
        quantity: 1,
      };
    });
  },
};
</script>

<style></style>
